<template>
  <div class="course">
    <div class="course_center">
      <!-- <h1>课程案例</h1> -->
      <header>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ routerName }}</el-breadcrumb-item>
          <!-- <el-breadcrumb-item v-if="nowtitle !== null">{{ nowtitle }}</el-breadcrumb-item> -->
        </el-breadcrumb>
      </header>
      <aside>
        <el-card class="box-card">
          <div class="box_input">
            <el-input placeholder="请输入内容" prefix-icon="el-icon-search" v-model="topic" @change="namesearch"></el-input>
            <el-button type="primary" size="mini" @click="namesearch">搜索</el-button>
          </div>
          <div class="box_search">
            <div class="search_one">
              <p class="s_title">学段选择</p>
              <p class="s_content">
                <span
                  v-for="item in perioddata"
                  :key="item.id"
                  @click="cutperiod(item.id)"
                  :class="item.id == searchfrom.periodId ? 'spanblue' : 'spanblack'"
                >{{ item.name }}</span>
              </p>
            </div>
            <div class="search_one">
              <p class="s_title">年级选择</p>
              <p class="s_content">
                <span
                  v-for="item in gradedata"
                  :key="item.id"
                  @click="cutgrade(item.id)"
                  :class="item.id == searchfrom.gradeId ? 'spanblue' : 'spanblack'"
                >{{ item.name }}</span>
              </p>
            </div>
            <div class="search_one">
              <p class="s_title">学科类型</p>
              <p class="s_content">
                <span
                  v-for="item in subjectypedata"
                  :key="item.id"
                  @click="cutserviceType(item.id)"
                  :class="item.id == searchfrom.serviceTypeId ? 'spanblue' : 'spanblack'"
                >{{ item.name }}</span>
              </p>
            </div>
            <div class="search_one">
              <p class="s_title">学科选择</p>
              <p class="s_content">
                <span
                  v-for="item in subjectdata"
                  :key="item.id"
                  @click="cutsubject(item.id)"
                  :class="item.id == searchfrom.subjectId ? 'spanblue' : 'spanblack'"
                >{{ item.name }}</span>
              </p>
            </div>
            <div class="search_one">
              <p class="s_title">系列课程</p>
              <p class="s_content">
                <span
                  v-for="item in serisedata"
                  :key="item.id"
                  @click="cutserise(item.id)"
                  :class="item.id == seriesId ? 'spanblue' : 'spanblack'"
                >{{ item.name }}</span>
              </p>
            </div>
          </div>
        </el-card>
      </aside>
      <nav>
        <el-card class="box-card">
          <div class="box_center">
            <div class="box_sort" v-for="item in sortdatas" :key="item.id" :class="item.id == sortid ? 'light' : 'dark'">
              <span>{{ item.name }}</span>
              <img src="../../assets/icon/none.png" alt v-if="item.sortType == null" @click="cuticon(1, item.id)" />
              <img src="../../assets/icon/up.png" alt v-if="item.sortType == 1" @click="cuticon(2, item.id)" />
              <img src="../../assets/icon/down.png" alt v-if="item.sortType == 2" @click="cuticon(null, item.id)" />
            </div>
          </div>
        </el-card>
      </nav>
      <main>
        <div v-if="emptyShow">
          <el-empty description="暂无数据"></el-empty>
        </div>
        <div v-else>
          <div v-if="seriesId == null" class="pcourse">
            <card v-for="item in listData" :key="item.id" :carddata="item" :cardsite="0"></card>
          </div>
          <div v-else class="pcourse">
            <cards v-for="item in listDatas" :key="item.id" :carddata="item"></cards>
          </div>
        </div>
      </main>
      <footer>
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="curpage"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </footer>
    </div>
  </div>
</template>
<script>
import card from "@/components/card";
import cards from "@/components/cards";
export default {
  data() {
    return {
      // 面包屑
      routerName: null,
      // asid的数据
      subjectypedata: null,
      subjectdata: null,
      perioddata: null,
      gradedata: null,
      serisedata: null,
      // 表单提交
      topic: null, //输入框
      seriesId: null, //系列课程
      searchfrom: {
        periodId: 0, //学段ID
        gradeId: 0, //年级ID
        serviceTypeId: 0, //类型
        subjectId: 0, //学科ID
      },

      // 普通课程
      listData: null,
      // 系列课程
      listDatas: null,

      // 排序
      sortdata: null,
      sorticon: null, //排序的升降
      sortid: null, //排序的id

      // 空数据
      emptyShow: false,
      // 分页
      curpage: 1,
      pageSize: 12,
      total: null,
    };
  },
  created() {
    console.log(this.$route.meta.title, "路由");
    this.routerName = this.$route.meta.title;
    this.querySubjectType(); //获取课程类型
    this.querySubject(0); //获取学科信息
    this.queryPeriod(); //获取学段
    this.queryGrade(0); //获取年级信息
    this.querySeriseLable(); //获取系列课程标签
    //
    console.log(this.$route.query.id, "oopid");
    if (this.$route.query.id == undefined) {
      // 获取普通课程
      this.queryCourselist();
    } else {
      this.seriesId = this.$route.query.id;
      this.querySeriseCourse();
    }

    //
    this.getsort(); //获取排序数据
  },
  methods: {
    // 获取课程类型
    async querySubjectType() {
      await this.$Http.querySubjectType().then((res) => {
        console.log(res, "获取课程类型");
        if (res.status == 200) {
          let aa = {
            id: 0,
            name: "全部",
          };
          this.subjectypedata = [aa, ...res.data];
        }
      });
    },
    // 获取学科信息
    async querySubject(id) {
      let ids = [];
      if (id !== 0) {
        ids.push(id);
      }
      const { data, status } = await this.$Http.querySubject({
        idList: ids,
      });
      if (status == 200) {
        console.log(data, status, "获取学科");
        let aa = {
          id: 0,
          name: "全部",
        };
        this.subjectdata = [aa, ...data];
      }
    },
    // 获取学段
    async queryPeriod() {
      await this.$Http.queryPeriod().then((res) => {
        console.log(res, "获取学段");
        if (res.status == 200) {
          let aa = {
            id: 0,
            name: "全部",
          };
          this.perioddata = [aa, ...res.data];
        }
      });
    },
    // 获取年级信息
    async queryGrade(id) {
      console.log(id);
      let ids = [];
      if (id !== 0) {
        ids.push(id);
      }
      const { data, status } = await this.$Http.queryGrade({
        idList: ids,
      });
      if (status == 200) {
        console.log(data, status, "获取年级信息");
        let aa = {
          id: 0,
          name: "全部",
        };
        this.gradedata = [aa, ...data];
      }
    },
    // 获取系列课程的标签
    async querySeriseLable() {
      await this.$Http.querySeriseLable().then((res) => {
        console.log(res, "获取系列课程");
        if (res.status == 200) {
          let aa = {
            id: 0,
            name: "全部",
          };
          this.serisedata = [aa, ...res.data];
        }
      });
    },

    // --查询普通课程
    async queryCourselist() {
      let periodId = this.searchfrom.periodId; //学段ID
      let gradeId = this.searchfrom.gradeId; //年级ID
      let subjectTypeId = this.searchfrom.serviceTypeId; //类型
      let subjectId = this.searchfrom.subjectId; //学科ID
      if (periodId == 0) {
        periodId = null;
      }
      if (gradeId == 0) {
        gradeId = null;
      }
      if (subjectTypeId == 0) {
        subjectTypeId = null;
      }
      if (subjectId == 0) {
        subjectId = null;
      }
      let datas = JSON.stringify({
        // courseIdList: classdata.classInformation, //课程id集合
        courseName: this.topic, //课程名称
        courseType: 1, //课程类型(1-课程，2-资料)
        periodId, //学段ID
        gradeId, //年级ID
        subjectTypeId, //类型
        subjectId, //学科ID
        sortId: this.sortid || 1, //排序ID(1-上传时间，2-播放量，3-评价数量，4-收藏数量，5-点赞数量，6-下载量)
        sortType: this.sorticon || 2, //排序类型（1-正序，2-倒序）
        pageNo: this.curpage, //页码
        pageSize: this.pageSize, //每页数量
      });
      const { data, status } = await this.$Http.queryCourselist(datas);
      if (status === 200) {
        if (data == null) {
          console.log(data, status, "获取课程");
          this.emptyShow = true;
          this.listData = null;
          this.total = 0;
        } else {
          console.log(data, status, "获取课程", data.list);
          this.emptyShow = false;
          this.listData = data.list;
          this.total = data.total;
        }
      }
    },
    //获取系列课程
    async querySeriseCourse() {
      let labelId = this.seriesId;
      if (labelId == 0) {
        labelId = null;
      }
      let datas = JSON.stringify({
        labelId, //标签
        pageNo: this.curpage, //页码
        pageSize: this.pageSize, //每页数量
        seriseName: this.topic, //系列名称
        sortId: this.sortid || 1, //排序ID(1-上传时间，2-点赞量，3-收藏量)
        sortType: this.sorticon || 2, //排序类型（1-正序，2-倒序）
      });
      const { data, status } = await this.$Http.querySeriseCourse(datas);
      if (status === 200) {
        if (data == null || data == "") {
          this.emptyShow = true;
          this.listDatas = null;
          this.total = 0;
        } else {
          this.emptyShow = false;
          this.listDatas = data.list;
          this.total = data.total;
        }
        console.log(data, status, "获取系列课程");
      }
    },
    // 按名字查询
    namesearch() {
      console.log(this.topic, "搜索");
      if (this.seriesId == null) {
        this.queryCourselist().then(() => {
          this.topic = null;
        });
      } else {
        this.querySeriseCourse().then(() => {
          this.topic = null;
        });
      }
    },

    // ---切换查询条件
    cutperiod(id) {
      console.log(id, "学段");
      this.searchfrom.periodId = id;
      this.queryGrade(id);
      this.queryCourselist();
    },
    cutgrade(id) {
      console.log(id, "年级");
      this.searchfrom.gradeId = id;
      this.queryCourselist();
    },
    cutserviceType(id) {
      console.log(id, "学科类型");
      this.searchfrom.serviceTypeId = id;
      this.querySubject(id);
      this.queryCourselist();
    },
    cutsubject(id) {
      console.log(id, "学科");
      this.searchfrom.subjectId = id;
      this.queryCourselist();
    },
    cutserise(id) {
      this.seriesId = id;
      this.querySeriseCourse();
    },

    //  获取排序
    getsort() {
      let aa = [
        {
          id: 1,
          name: "上传时间",
          sortType: null,
        },
        {
          id: 2,
          name: "按播放量",
          sortType: null,
        },
        {
          id: 3,
          name: "评价数量",
          sortType: null,
        },
        {
          id: 4,
          name: "收藏数量",
          sortType: null,
        },
        {
          id: 5,
          name: "点赞数量",
          sortType: null,
        },
      ];
      this.sortdata = aa;
    },
    getsorts() {
      let aa = [
        {
          id: 1,
          name: "上传时间",
          sortType: null,
        },
        {
          id: 2,
          name: "点赞数量",
          sortType: null,
        },
        {
          id: 3,
          name: "收藏数量",
          sortType: null,
        },
      ];
      this.sortdata = aa;
    },
    // ---切换排序
    cuticon(num, id) {
      console.log(num, id, "当前num");
      this.sorticon = num;
      this.sortid = id;
      if (this.seriesId == null) {
        this.queryCourselist();
      } else {
        this.querySeriseCourse();
      }
    },

    // 分页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`, this.seriesId);
      this.curpage = val;
      console.log(this.seriesId == null, "okals");
      if (this.seriesId == null) {
        this.queryCourselist();
      } else {
        this.querySeriseCourse();
      }
    },
  },
  watch: {
    seriesId() {
      // console.log(this.seriesId, '系列课程的id111')
      if (this.seriesId !== null) {
        this.searchfrom.periodId = null;
        this.searchfrom.gradeId = null;
        this.searchfrom.serviceTypeId = null;
        this.searchfrom.subjectId = null;
        this.curpage = 1;
        this.querySeriseCourse();
        this.getsorts();
      }
    },
    searchfrom: {
      handler() {
        // console.log(this.seriesId, '系列课程的id222')
        for (let key in this.searchfrom) {
          // if (this.seriesId !== null && this.searchfrom[key] === null) {
          // } else
          if (this.seriesId !== null && this.searchfrom[key] !== null) {
            this.curpage = 1;
            this.queryCourselist().then(() => {
              this.seriesId = null;
              this.getsort();
              for (let keys in this.searchfrom) {
                if (this.searchfrom[keys] === null) {
                  this.searchfrom[keys] = 0;
                }
              }
            });
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    sortdatas() {
      console.log(this.sortdata, "sortdata");
      let editdata = this.sortdata;
      if (editdata == null) {
        return;
      } else {
        for (let x = 0; x < editdata.length; x++) {
          editdata[x].sortType = null;
          if (editdata[x].id == this.sortid) {
            if (this.sorticon == null) {
              editdata[x].sortType = null;
            } else if (this.sorticon == 1) {
              editdata[x].sortType = 1;
            } else if (this.sorticon == 2) {
              editdata[x].sortType = 2;
            }
          }
        }
        return editdata;
      }
    },
  },
  components: {
    card,
    cards,
  },
};
</script>
<style lang="scss" scoped>
.course {
  .course_center {
    width: 1200px;
    margin: 0 auto;

    header {
      padding: 15px 0;
    }

    aside {
      .box-card {
        padding: 0 20px;

        .box_input {
          display: flex;
          justify-content: flex-start;

          .el-input {
            width: 500px;
            height: 40px;
          }

          .el-button {
          }
        }

        .box_search {
          margin-top: 25px;

          .search_one {
            display: flex;
            justify-content: flex-start;
            padding: 5px 0;

            .s_title {
              width: 70px;
              // height: 18px;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #141516;
              // line-height: 16px;
            }

            .s_content {
              // width: 56px;
              // height: 18px;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              // color: #5D6066;
              // line-height: 16px;
              margin-left: 20px;

              span {
                // width: 56px;
                display: inline-block;
                margin-right: 20px;
                margin-bottom: 10px;
                cursor: pointer;
              }

              .spanblue {
                color: #1977f9;
              }

              .spanblack {
                color: #5d6066;
              }

              span:hover {
                color: #1977f9;
              }
            }
          }
        }
      }
    }

    nav {
      margin-top: 30px;

      .box-card {
        .box_center {
          display: flex;
          justify-content: flex-start;

          .box_sort {
            margin-left: 20px;

            img {
              cursor: pointer;
            }

            span {
              // width: 56px;
              // height: 18px;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              // color: #1977F9;
              // line-height: 16px
            }
          }

          .light {
            span {
              color: #1977f9;
            }
          }

          .dark {
            span {
              color: #141516;
            }
          }
        }
      }
    }

    main {
      margin-top: 15px;

      .pcourse {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        .card {
          margin-left: 12px;
          margin-right: 12px;
        }
        .cards {
          margin-left: 12px;
          margin-right: 12px;
        }
      }
    }

    footer {
      margin-top: 15px;
      display: flex;
      justify-content: center;
    }
  }
}
</style>